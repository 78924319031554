@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

html {
    font-family: 'Montserrat', sans-serif;
    background: #e3f2fb;
}

.fill-warning {
    fill: #fd564d !important;
}

.img-header-cp {
    -webkit-clip-path: url(#shape);
    clip-path: url(#shape);
}

.slider__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.range-slider__wrapper {
    margin: 15px 25px;
}

.selected__value {
    position: absolute;
    top: 40px;
    left: 50%;
    font-weight: 600;
    min-width: 70px;
}

.slider__circle:focus-visible, .slider__circle:focus {
    outline: none !important;
}

.label-amount {
    width: 50px;
}

.small-text {
    font-size: 14px;
}

.payment__wrapper {
    background: rgb(227, 242, 251);
    background: linear-gradient(160deg, rgba(227, 242, 251, 1) 0%, rgba(213, 231, 241, 1) 100%);

}

.accordion__wrapper {
    border-radius: 10px;
}

.accordion__button {
    padding: 22px 20px 22px 20px;
    font-weight: 600;

    line-height: 24px;
    background: #fff;

    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin-top: 15px;
    transition: 0s .4s;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.accordion__button h5 {
    font-size: 17px;
}

.accordion__panel {
    background: #fff;
    border-radius: 0 0 10px 10px;
    padding: 10px 20px;
    border-top: 1px solid #e8f3f1;
    line-height: 1.5;
    text-align: left;
    margin-top: -12px;
}

.nav-link:hover {
    cursor: pointer;
}


@media (min-width: 601px) {
    .popup-body {
        position: relative;
        background: white;
        width: 35vw;
        padding: 20px;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 20vh;
    }

    .popup-wrapper {
        position: absolute;
        background: rgba(0, 0, 0, 0.92);
        width: 100vw;
        height: 100vh;
        z-index: 1111;
        margin-top: -80px;
    }

    #headerSection {
        margin-top: 90px;
    }

    .lg-header {
        background: #e3f2fb;
        padding: 20px 0 !important;
    }

    .form__flex {
        flex: 0 0 60%
    }

    .form-section__wrapper {
        margin-top: 80px;
        background: #e3f3fb
    }
}

@media (max-width: 600px) {
    .popup-body {
        position: relative;
        background: white;
        width: 85vw;
        padding: 20px;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 20vh;
    }

    .popup-wrapper {
        position: absolute;
        background: rgba(0, 0, 0, 0.92);
        width: 100vw;
        height: 100%;
        z-index: 1111;
        margin-top: -80px;
    }

    #headerSection {
        margin-top: 50px;
    }

    .lg-header {
        background: #e3f2fb;
        padding: 10px 0 !important;
    }

    .form__flex {
        flex: 1
    }

    .form-section__wrapper {
        margin-top: 45px;
        background: #e3f3fb
    }
}

.header__menu {
    margin-left: 30px;
}

.form__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.application-form-label {
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin-bottom: 14px;

}

.form-label__span {
    display: inline-block;
    color: #24292d;
    font-size: larger;
}

.from-label__div {
    margin-top: 8px !important;
}

.validation__error {
    font-size: 12px;
    color: #fd564d;
}

.dbg_button {
    position: fixed;
    margin-top: 90px;
    z-index: 9999;
}

.preloader__section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e3f3fb;
    height: 100vh;
    flex-direction: column;
}

.suggestions__wrapper {
    position: relative;
    z-index: 999;
    background: white;
    width: 100%;
    margin-top: -1px;
    overflow: scroll;
}

.card {
    border-radius: 15px !important;
}

.flat-card-body {
    margin-top: -11px;
    background: #fff;
    padding-top: 20px;
    border-radius: 0 0 15px 15px;
}

.phone-mask__item {
    color: #747579 !important;
    font-size: 20px !important;
    padding: 4px 12px !important;
}

.terms-accept .form-check-label {
    margin-left: 8px;
    font-size: 14px;
    margin-top: 2px;
    display: inline;
}

.terms-accept .form-check-input {
    height: 1.3em;
    width: 1.3em;
}

.terms-accept .validation__error {
    display: block;
}

.request-new-code {
    color: #fd564d;
    border-bottom: 1px dashed;
}

.request-new-code:hover {
    border-bottom: none;
    cursor: pointer;
}

.radio-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
}

.radio-wrapper label {
    margin-left: 6px;
}

.radio-wrapper .form-check-input {
    width: 1.4em;
    height: 1.4em;
}

.radio-wrapper .form-check-label {
    font-size: 16px;
    margin-top: 2px;
}

.form-select {
    border-color: #dee0e3 !important;
}

.full-suggestion__wrapper {
    position: relative;
}

.disabled-list-item {
    font-size: 12px;
    color: #b5b5b5;
    background: #f8f8f8 !important;
}

.disabled-list-item:hover {
    cursor: default !important;
}

.list-group-item:hover {
    background: #f8f8f8;
    cursor: pointer;
}

.list-group-item {
    border-left-color: #005fcc !important;
    border-right-color: #005fcc !important;
}

.list-group-item:last-child {
    border-bottom-color: #005fcc !important;
}

.list-group {
    border-radius: 0 0 0.325rem 0.325rem !important;
    margin-top: -2px;
}

.with-suggestions {
    border-radius: 0.325rem 0.325rem 0 0 !important;
    border-bottom-color: #f8f8f8 !important;
}

.same-address-checker {
    position: relative;
}

.same-address-checker:hover {
    cursor: pointer;
}

.same-address-checker_checked:hover {
    cursor: pointer;
}

.same-address-checker_checked::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fd564d;
    border: 3px solid #fff;
    border-radius: 10px;
    outline: 1px solid #fd564d;

}

.same-address-checker:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #fd564d;
    border-radius: 10px;
}

.same-address-checker__label {
    margin-left: 27px;
    font-size: 16px;
}


